/**
 * 参数处理
 * @param {*} params  参数
 */
 export function tansParams(params) {
  let result = '';
  for (const propName of Object.keys(params)) {

    const value = params[propName];
    var part = encodeURIComponent(propName) + '=';
    // 移除对空字符串的判断
    // console.log('propName :>> ', propName,params[propName]);
    if (value !== null && typeof value !== 'undefined') {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          // 移除对空字符串的判断
          if (value[key] !== null && typeof value[key] !== 'undefined') {
            let subKey = encodeURIComponent(propName + '[' + key + ']');
            result += subKey + '=' + encodeURIComponent(value[key]) + '&';
          }
        }
      } else {
        result += part + encodeURIComponent(value) + '&';
      }
    }
  }
  // 移除最后一个未使用的&字符
  return result.length ? result.slice(0, -1) : result;
}