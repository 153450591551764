import { createApp } from "vue";
import {
  Button,
  Toast,
  Popup,
  Picker,
  Swipe,
  SwipeItem,
  Icon
} from "vant";
import 'vant/lib/index.css';
const app = createApp();
app.use(Button);
app.use(Toast);
app.use(Popup);
app.use(Picker);
app.use(Swipe);
app.use(SwipeItem);
app.use(Icon);