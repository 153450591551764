import "core-js/stable";
import "regenerator-runtime/runtime";

import { createApp,reactive  } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

router.afterEach((to, from, next) => {
  window, scrollTo(0, 0);
});

import "./common/global/index";
import "../rem";

store.dispatch("loadLocal");
let app = createApp(App)

app.use(store).use(router).mount('#app')
