import { createStore } from 'vuex'
import { getzsk,getagents } from "@/api/index";
import Vue from 'vue';
export default createStore({
  state: {
    token: null,
    userInfo: null,
    zsks: [],
    mytit:"",
    myapi:"",
    agents:[],
    user_app_id:0,
    is_c:0,
    sig:"",
  },
   getters: {
    hasActiveZsk: state => {
      // 检查数组中是否有激活的数据
      return state.zsks.some(item => item.is_activate === 1);
    }
  },
  mutations: {
    settitle_local(state,e){
      state.mytit = e;
      window.localStorage.setItem('mytit', e);
    },
    setappid_local(state,e){
      state.user_app_id = e;
      window.localStorage.setItem('user_app_id', e);
    },
    setsig(state,e){
      state.sig = e; 
      window.localStorage.setItem('sig', e);
    },
    setis_c(state,e){
      state.is_c = e; 
      window.localStorage.setItem('is_c', e);
    },
    settitle(state,e){
      state.mytit = e;
    },
    setappid(state,e){
      state.user_app_id = e;
    },
    setapi(state,e){
      state.myapi = e;

    },
    SET_ZSKS(state, zsks) {
      // 更新zsks数组
      state.zsks = zsks;
    },
    SET_AGENT(state, agents) {
      // 更新agents数组
      state.agents = agents;
    },
    ACTIVATE_ZSK(state, knowledge_id) {
      // 激活指定的知识库
      // state.zsks.find(item => item.knowledge_id === knowledge_id)
      const index = state.zsks.findIndex(item => item.knowledge_id === knowledge_id);
      let k = Number(state.zsks[index]['is_activate'])
      state.zsks.forEach((item,indexs)=>{
        if(index==indexs && !k){
          item.is_activate = 1
        }else if(
          index==indexs && k
        )
        item.is_activate = 0

    })
    },
    // token
    SET_TOKEN: (state, token) => {
      state.token = token
      window.localStorage.setItem('token', token)
    },
    REMOVE_TOKEN: (state) => {
      state.token = null
      window.localStorage.removeItem('token')
    },

    // userInfo
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
      window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    REMOVE_USERINFO: (state) => {
      state.userInfo = null
      window.localStorage.removeItem('userInfo')
    },
  },
  actions: {
    fetchZsks({ commit }) {
      // 假设getzsk是一个返回promise的函数，用于获取zsks数据
      getzsk().then(res => {
        if (res.data) {
          let zsks = res.data.map(item => JSON.parse(item));
          commit('SET_ZSKS', zsks); // 用获取到的数据更新zsks数组
        }
      }).catch(error => {
        console.error('Failed to fetch zsks:', error);
      });
    },
    fetchAgents({ commit }) {
      getagents({ tag: "" }).then(res => {
        if (res.data) {
          let agents = res.data.map(item => JSON.parse(item));
          commit('SET_AGENT', agents); // 用获取到的数据更新agents数组
          agents.forEach(element => {
            // //console.log('element :>> ', element);
            if (Number(element.is_activate)) {
               commit("settitle", "@agent@"+element.app_title);
               commit("setappid",element.id);
            }
         });
        }
      }).catch(error => {
        console.error('Failed to fetch agents:', error);
      });
    },
    // 退出登录
    outLoginClick({ commit }) {
      commit('REMOVE_TOKEN')
      commit('REMOVE_USERINFO')
    },
    // 如有缓存直接赋值vuex 不去请求接口
    loadLocal({ commit }) {
      const token = window.localStorage.getItem('token')
      if (token && token != 'undefined') {
        commit('SET_TOKEN', token)
      }
      const userInfo = window.localStorage.getItem('userInfo')
      if (userInfo && userInfo != 'undefined') {
        commit('SET_USERINFO', JSON.parse(userInfo))
      }
    },
  },
  modules: {},
})
