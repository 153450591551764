import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () =>
      import(/* webpackChunkName: "index" */ '../views/index.vue'),
    meta: {
      title: '奇熵智数',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/login.vue'),
    meta: {
      title: '登录',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () =>
      import(/* webpackChunkName: "register" */ '../views/register.vue'),
    meta: {
      title: '注册',
    },
  },
  {
    path: '/chatList',
    name: 'chatList',
    component: () =>
      import(/* webpackChunkName: "chatList" */ '../views/chatList.vue'),
    meta: {
      title: '聊天列表',
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
const whiteList = ['/login', '/register']
// 全局前置守卫
router.beforeEach((to, from, next) => {
  let token = isUserAuthenticated()
  if (token) { // 有token
 
    if (to.path === '/login') {
      next('/') // 去首页
    } else {
  
        next() // 放行

   
    }
  } else { // 无token
  
    if (whiteList.includes(to.path)) {
      next() // 放行
    } else {
      //console.log('gun')
      next('/login') // 去登录页
    }
  }
});

function isUserAuthenticated() {
  
  return store.state.token;
}


export default router
