import request from '@/utils/request'
import store from '@/store';
// 用户登录
export function user_login(params) {
  return request({
    url:store.state.myapi+'user/user_login',
    method: 'get',
    params,
  })
}

// 用户注册
export function create_user(params) {
  return request({
    url: store.state.myapi+'user/create_user',
    method: 'get',
    params,
  })
}
// 知识库
// searchvalue: Optional[str] 角色/提示词模板 搜索词,
export function getzsk(params) {
  return request({
    url: store.state.myapi+'knowledge/get_user_knowledge_list',
    method: 'get',
    params,
  })
}

// agent
export function getagents(params) {
  return request({
    url: store.state.myapi+'app_store/getapplist_for_operator',
    method: 'get',
    params,
  })
}

// 角色
export function getrole(params) {
  return request({
    url: store.state.myapi+'prompt/getpromptlistAll',
    method: 'get',
    params,
  })
}
// /ai_api_1/prompt/getpromptlist
export function zskss(params) {
  return request({
    url: store.state.myapi+'prompt/getpromptlist',
    method: 'get',
    params,
  })
}
// 激活知识库
// knowledge_id：知识库id
export function jhzsk(params) {
  return request({
    url: store.state.myapi+'knowledge/activate_knowledge',
    method: 'get',
    params,
  })
}
// 激活角色
// prompt_id: Optional[str] 角色/提示词模板 id
export function jhrole(params) {
  return request({
    url: store.state.myapi+'prompt/activatePrompt',
    method: 'get',
    params,
  })
}

export function jhagent(params) {
  return request({
    url: store.state.myapi+'app_store/activate_agent',
    method: 'get',
    params,
  })
}

export function delete_agent_history(params) {
  return request({
    url: store.state.myapi+'data/delete_agent_history',
    method: 'get',
    params,
  })
}

// /ai_api_1/prompt/getprompttags
export function getbarlist(params) {
  return request({
    url: store.state.myapi+'prompt/getprompttags',
    method: 'get',
    params,
  })
}
// /ai_api_1/prompt/disActivatePrompt?prompt_id=263
//取消激活角色
export function qxjhrole(params) {
  return request({
    url: store.state.myapi+'prompt/disActivatePrompt',
    method: 'get',
    params,
  })
}
// 非知识库会话
export function huihua(data) { 
  return request({
    url: store.state.myapi+'stream_chat',
    method: 'post',
    data,contentType:'application/json',responseType: 'stream'
  })
}
export function getServicesHostConfig(params){
  return request({
    url: store.state.myapi+'services/get_services_host_config_list',
    method: 'get',
    params,
  })
}

/*
输入参数：
question: str 输入的问题,
history: List[List[str]] 本地缓存的历史，一问一答组成一个list（一轮会话），默认上传本地的最近20轮会话，格式如下：[["中国首都在哪","首都北京"],["中国首都在哪","北京"]],
fileList: List[str] 这里值设置成空数组：[],
uuid: str 会话窗口的唯一id，为每个会话窗口自动生成一个此id，建议用时间戳生成,
token: str 用户的token,
channel: str 请求来源，这里设置为："system",
service_id: int 这里设置为：0,
user_plugin_id: int 这里设置为：0,
use_knowledge: int 如果切换到知识库会话，这里设置为1，否则为0,
chat_search_data: str 这里设置为："",
*/

//知识库会话
export function huihua_zsk(data) { 
  return request({
    url: store.state.myapi+'local_doc_qa/local_doc_chat',
    method: 'post',
    data,contentType:'application/json',responseType: 'stream'
  })
}

/*

question: str 输入的问题,
history: List[List[str]] 本地缓存的历史，一问一答组成一个list（一轮会话），默认上传本地的最近20轮会话，格式如下：[["中国首都在哪","首都北京"],["中国首都在哪","北京"]],
fileList: List[str] 这里值设置成空数组：[],
uuid: str 会话窗口的唯一id，为每个会话窗口自动生成一个此id，建议用时间戳生成,
token: str 用户的token,
stream: str 是否采用流式输出,这里设置为："true"
channel: str 请求来源，这里设置为："system",
service_id: int 这里设置为：0,
user_plugin_id: int 这里设置为：0,
use_knowledge: int 如果切换到知识库会话，这里设置为1，否则为0,


*/